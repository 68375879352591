import React, { ReactNode } from "react";
import { MDXProvider } from "@mdx-js/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const shortcodes = { GatsbyImage, getImage };

export default function MdxProvider({ children }: Props) {
  return <MDXProvider components={shortcodes}>{children}</MDXProvider>;
}

type Props = {
  children: ReactNode;
};
