module.exports = {
  url: "https://www.fandomnesia.com/",
  pathPrefix: "",
  SITE_TITLE: "Fandomnesia",
  SITE_SUBTITLE: "Fandomnesia is an updated information site and interesting content about Geek Culture",
  SITE_DESCRIPTION:
    "Fandomnesia is an updated information site and interesting content about Geek Culture, Film, Otaku, K-Pop and K-Drama.",
  KEYWORDS: "K-Pop, K-Drama, Geek Culture, Film, Otaku",
  HERO_TITLE:
    "Explore various updated content and info about K-Pop, K-Drama, Movies, and Otaku on Fandomnesia.",
  HERO_SUBTITLE: "Website Designed by JUXT Design",
  COPYRIGHT: "Content by Fandomnesia and Web Design by JUXT Design",
  LOCALE: "en",
  POST_PER_PAGE: 6,
  MENU: [
    {
      label: "About",
      path: "/about/",
    },
    {
      label: "Topics",
      path: "/topics/",
    },
    {
      label: "Home",
      path: "/",
    },
  ],
  FOOTER: [
    {
      label: "About",
      path: "/about/",
    },
    {
      label: "Privacy",
      path: "/privacy-policy/",
    },
    {
      label: "Contact",
      path: "/contact/",
    },
    {
      label: "Disclaimer",
      path: "/disclaimer/",
    },
  ],
  AUTHOR: {
    NAME: "Ilham Maulana",
    TWITTER: "@impwriters",
  },
};
